<template lang="pug">
div
  .sidebar-input-wrapper.d-flex.justify-center
    .sidebar-title-tooltip-container.pdl-0.w-100
      label.w-100.mb-0.font-weight-bold.bb-2.pb-1(v-if="label.length" style="position: static") {{ $t(label) }}
  slot
</template>

<script>
  export default {
    props: {
      label: { type: String, default: '' },
    },
  };
</script>
<style lang="sass">
  .editor
    .bb-2
      color: rgba(80, 87, 99, 1)
      border: 0 !important
</style>
